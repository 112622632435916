<template>
  <div class="body">
    <pageTitle id="dom1" :title="title"></pageTitle>
    <div id="dom2" class="head bb p20 flex fw_w">
      <div>
        <div>关键词</div>
        <div class="w300">
          <el-input
            clearable
            :size="size"
            v-model="search"
            placeholder="请输入用户名/手机号/姓名"
          ></el-input>
        </div>
      </div>
      <div>
        <div>组别</div>
        <div>
          <el-select :size="size" v-model="group_id" placeholder="请选择">
            <el-option
              v-for="item in group_arr"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div>
        <el-button @click="retrieval" type="primary">搜 索</el-button>
      </div>
    </div>
    <div
      :style="{ height: `calc(100% - (${dom1 + 'px'} + ${dom2 + 'px'}))` }"
      v-loading="userListLoading"
    >
      <div
        class="bb pl20 pr20"
        :style="{ height: `calc(100% - (${dom3 + 'px'}))` }"
      >
        <el-table :data="tableData" height="100%" style="width: 100%">
          <el-table-column label="ID" width="100" prop="id"> </el-table-column>
          <el-table-column label="用户名" width="250" prop="service_name">
          </el-table-column>
          <el-table-column label="姓名" width="150" prop="nickname">
          </el-table-column>
          <el-table-column label="手机号" width="120" align="center">
            <template #default="scope">
              <div v-if="currentShowMobileIndex == scope.$index">
                {{ scope.row.service_phone }}
              </div>
              <div v-else>{{ scope.row.mobileText }}</div>
              <el-button
                v-if="scope.row.service_phone"
                type="primary"
                size="small"
                @click="currentShowMobileIndex = scope.$index"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            label="是否在线"
            width="100"
            align="center"
            prop="is_online"
          >
            <template #default="scope">
              <span v-if="scope.row.is_online == 1" style="color: green"
                >在线</span
              >
              <span v-if="scope.row.is_online == 0" style="color: red"
                >离线</span
              >
            </template>
          </el-table-column>
          <el-table-column
            label="创建时间"
            width="180"
            align="center"
            prop="createtime"
          >
          </el-table-column>
          <el-table-column
            label="组别"
            width="180"
            align="center"
            prop="group_text"
          >
          </el-table-column>
          <el-table-column label="微信二维码" align="center" width="130">
            <template #default="scope">
              <img
                v-if="scope.row.service_wechat_image"
                class="img_100"
                :src="scope.row.service_wechat_image"
                alt=""
              />
              <div v-else class="img_100"></div>
            </template>
          </el-table-column>
          <el-table-column label="公屏二维码" align="center" width="130">
            <template #default="scope">
              <img
                v-if="scope.row.service_chat_image"
                class="img_100"
                :src="scope.row.service_chat_image"
                alt=""
              />
              <div v-else class="img_100"></div>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="" align="right">
            <template #default="scope">
              <el-button
                type="primary"
                size="small"
                @click="handleEdit(scope.$index, scope.row)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div id="dom3" class="bb p20">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="15"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 编辑资料 -->
    <el-dialog width="680px" v-model="editFormShow" title="编辑资料">
      <el-form :model="editForm" ref="editForm">
        <div>
          <div class="form_cell">
            <el-form-item
              size="large"
              label="昵称"
              :label-width="formLabelWidth1"
            >
              <el-input
                disabled
                v-model="editForm.service_name"
                autocomplete="off"
              />
            </el-form-item>
            <el-form-item
              size="large"
              label="姓名"
              :label-width="formLabelWidth1"
            >
              <el-input v-model="editForm.nickname" autocomplete="off" />
            </el-form-item>
            <el-form-item
              size="large"
              label="手机号"
              :label-width="formLabelWidth1"
            >
              <el-input
                :disabled="is_service_admin != 1"
                v-model="editForm.service_phone"
                autocomplete="off"
              />
            </el-form-item>
            <el-form-item
              size="large"
              label="微信号"
              :label-width="formLabelWidth1"
            >
              <el-input
                :disabled="is_service_admin != 1"
                v-model="editForm.service_wechat_number"
                autocomplete="off"
              />
            </el-form-item>
            <div class="flex w100">
              <el-form-item
                size="large"
                label="组别"
                :label-width="formLabelWidth1"
              >
                <el-select
                  size="large"
                  v-model="editForm.group_id"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in group_arr"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <el-form-item
              :label-width="formLabelWidth1"
              size="large"
              label="微信二维码"
            >
              <el-upload
                class="avatar-uploader"
                action="https://api.i-vce.com/service/common/upload"
                :show-file-list="false"
                :on-success="handleAvatarSuccess1"
              >
                <div
                  class="avatar-uploader_div"
                  v-if="editForm.service_wechat_image"
                >
                  <img :src="editForm.service_wechat_image" class="avatar" />
                  <div class="zhezhao">
                    <el-icon @click.stop="handleRemove1" :size="24">
                      <Delete color="#ffffff" />
                    </el-icon>
                  </div>
                </div>
                <el-icon v-else class="avatar-uploader-icon">
                  <Plus />
                </el-icon>
              </el-upload>
            </el-form-item>
            <el-form-item
              :label-width="formLabelWidth1"
              size="large"
              label="公屏二维码"
            >
              <el-upload
                class="avatar-uploader"
                action="https://api.i-vce.com/service/common/upload"
                :show-file-list="false"
                :on-success="handleAvatarSuccess2"
              >
                <div
                  class="avatar-uploader_div"
                  v-if="editForm.service_chat_image"
                >
                  <img :src="editForm.service_chat_image" class="avatar" />
                  <div class="zhezhao">
                    <el-icon @click.stop="handleRemove2" :size="24">
                      <Delete color="#ffffff" />
                    </el-icon>
                  </div>
                </div>
                <el-icon v-else class="avatar-uploader-icon">
                  <Plus />
                </el-icon>
              </el-upload>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editFormShow = false">取消</el-button>
          <el-button type="primary" @click="submitForm('editForm')"
            >确定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as Service from "../api/managerList";
import { Delete, Plus } from "@element-plus/icons-vue";
import pageTitle from "../common/pageTitle.vue";
export default {
  components: {
    Plus,
    Delete,
    pageTitle,
  },
  data() {
    return {
      title: "客户经理列表",
      size: "medium",
      search: "", // 关键词
      service_id: 0, // 0全部客服
      serviceArr: [
        {
          id: 0,
          nickname: "全部",
        },
      ],
      userListLoading: false, // 会员列表加载状态
      tableData: [],
      page: 1,
      total: 0,
      addUserFormShow: false, // 添加假人表单显示状态
      addUserForm: {
        nickname: "",
        live_expiration_time: "",
      }, // 添加假人表单
      addUserRules: {
        nickname: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        live_expiration_time: [
          { required: true, message: "请选择等级到期时间", trigger: "change" },
        ],
      }, // 添加假人表单验证规则
      editFormShow: false, // 编辑资料表单显示状态
      editForm: {
        id: null,
        group_id: 0,
        service_name: "",
        nickname: "",
        service_wechat_image: "",
        service_chat_image: "",
        service_phone: "",
        service_wechat_number: "",
      }, // 编辑资料表单
      formLabelWidth: "60px",
      formLabelWidth1: "100px",
      teacherList: [], // 研究员列表
      serviceList: [], // 客服列表
      is_service_admin: localStorage.getItem("is_service_admin"), // 0非管理员1管理员
      group_arr: [
        {
          id: -1,
          name: "全部",
        },
      ], // 组别列表
      group_id: -1,

      currentShowMobileIndex: null, // 当前显示的手机号

      dom1: 0,
      dom2: 0,
      dom3: 0,
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.initTableHeight();
    this.getServiceBaseInfo();
    this.getServiceList(1);
  },
  methods: {
    onmessage(e) {},
    initTableHeight() {
      this.dom1 = document.getElementById("dom1").offsetHeight;
      this.dom2 = document.getElementById("dom2").offsetHeight;
      this.dom3 = document.getElementById("dom3").offsetHeight;
    },
    // 搜索操作
    retrieval() {
      this.getServiceList(1);
    },
    // 获取客服基础配置
    async getServiceBaseInfo() {
      const res = await Service.getServiceBaseInfo();
      this.group_arr = this.group_arr.concat(res.data.group_arr);
    },
    // 获取客服经理列表
    async getServiceList(page) {
      this.userListLoading = true;
      const res = await Service.getServiceList({
        page,
        search: this.search,
        group_id: this.group_id,
      });
      // console.log("客服经理列表", res);
      this.page = page;
      this.total = res.data.total;
      var tableData = res.data.data;
      const reg = /(\d{3})\d{4}(\d{4})/;
      for (const item of tableData) {
        item.mobileText = item.service_phone.replace(reg, "$1****$2");
      }
      this.tableData = tableData;
      this.userListLoading = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.profile();
        }
      });
    },
    // 修改客服详情
    async profile() {
      var editForm = this.editForm;
      var param = {
        id: editForm.id,
        group_id: editForm.group_id,
        nickname: editForm.nickname,
        service_wechat_image: editForm.service_wechat_image,
        service_chat_image: editForm.service_chat_image,
        service_phone: editForm.service_phone,
        service_wechat_number: editForm.service_wechat_number,
      };
      const res = await Service.editServiceInfo(param);
      // console.log("修改客服详情", res);
      this.$message.success(res.msg);
      this.editFormShow = false;
      this.$refs["editForm"].resetFields();
      this.getServiceList(this.page);
    },
    handleEdit(index, row) {
      this.editFormShow = true;
      // console.log(index, row);
      this.editForm = {
        id: row.id,
        group_id: row.group_id,
        service_name: row.service_name,
        nickname: row.nickname,
        service_wechat_image: row.service_wechat_image,
        service_chat_image: row.service_chat_image,
        service_phone: row.service_phone,
        service_wechat_number: row.service_wechat_number,
      };
    },
    handleRemove1() {
      this.editForm.service_wechat_image = "";
    },
    handleAvatarSuccess1(res, file) {
      this.editForm.service_wechat_image = res.data.fullurl;
    },
    handleRemove2() {
      this.editForm.service_chat_image = "";
    },
    handleAvatarSuccess2(res, file) {
      this.editForm.service_chat_image = res.data.fullurl;
    },
    handleDelete(index, row) {
      // console.log(index, row);
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.getServiceList(val);
      // console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style scoped>
.body {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.head {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 15px;
}

.head > div {
  display: flex;
  align-items: center;
}

.head > div > div:first-child {
  margin-right: 10px;
  font-size: 14px;
  /* margin-bottom: 10px; */
}

.w300 {
  width: 300px;
}

.w222 /deep/ .el-form-item__content {
  width: 222px !important;
  flex: initial !important;
}

.form_cell_title {
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid #e0e9f5;
  box-sizing: border-box;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.form_cell {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.w202 /deep/.el-input__inner {
  width: 202px !important;
}

.w202 .date /deep/.el-input__suffix {
  right: 33px !important;
}

.avatar-uploader_div {
  width: 200px;
  height: 200px;
  display: block;
  position: relative;
}

.avatar-uploader .avatar {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.zhezhao {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.zhezhao:hover {
  opacity: 1;
}

/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

/deep/.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

/deep/.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 200px;
  text-align: center;
}

.img_100 {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
</style>
