<template>
  <div class="body">
    <pageTitle id="dom1" :title="title"></pageTitle>
    <div id="dom2" class="head bb p20 flex fw_w">
      <div>
        <div>关键词</div>
        <div style="width: 240px">
          <el-input
            clearable
            :size="size"
            v-model="search"
            placeholder="请输入"
          ></el-input>
        </div>
      </div>
      <div>
        <div>发送人</div>
        <div style="width: 240px">
          <el-input
            :size="size"
            v-model="send_user"
            placeholder="请输入昵称/手机号/真实姓名"
          ></el-input>
        </div>
      </div>
      <div>
        <div>接收人</div>
        <div style="width: 240px">
          <el-input
            :size="size"
            v-model="accept_user"
            placeholder="请输入昵称/手机号/真实姓名"
          ></el-input>
        </div>
      </div>
      <div>
        <div>渠道</div>
        <div style="width: 140px">
          <el-select v-model="channel_id" :size="size" placeholder="请选择">
            <el-option label="全部" value=""> </el-option>
            <el-option
              v-for="item in channelArr"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div>
        <div>时间</div>
        <div>
          <el-date-picker
            style="width: 240px"
            format="YYYY-MM-DD"
            value-format="x"
            :size="size"
            v-model="time_range"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </div>
      <div>
        <el-button @click="retrieval" type="primary">搜 索</el-button>
      </div>
    </div>
    <div
      :style="{ height: `calc(100% - (${dom1 + 'px'} + ${dom2 + 'px'}))` }"
      v-loading="listLoading"
    >
      <div
        class="bb pl20 pr20"
        :style="{ height: `calc(100% - (${dom3 + 'px'}))` }"
      >
        <el-table :data="tableData" height="100%" style="width: 100%">
          <el-table-column label="ID" width="120" prop="id"> </el-table-column>
          <el-table-column label="发送人" width="180" prop="user.nickname">
          </el-table-column>
          <el-table-column label="发送时间" width="180" prop="createtime_text">
          </el-table-column>
          <el-table-column label="接收人" width="180" prop="to_user.nickname">
          </el-table-column>
          <el-table-column show-overflow-tooltip label="内容" prop="content">
          </el-table-column>
          <el-table-column label="关键词" width="150" prop="name">
          </el-table-column>
          <el-table-column label="渠道" width="120" prop="user.user_type">
          </el-table-column>
          <el-table-column label="IP" width="140" prop="to_user.joinip">
          </el-table-column>
        </el-table>
      </div>
      <div id="dom3" class="bb p20">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="15"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as Service from "../api/chatRecord";
import pageTitle from "../common/pageTitle.vue";
export default {
  components: {
    pageTitle,
  },
  data() {
    return {
      title: "聊天记录",
      size: "medium",
      search: "", // 关键词
      time_range: [], // 时间
      send_user: null, // 发送人
      accept_user: null, // 接收人
      tableData: [],
      page: 1,
      total: 0,
      listLoading: false,
      channel_id: "",
      channelArr: [],

      dom1: 0,
      dom2: 0,
      dom3: 0,
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.initTableHeight();
    this.getBaseInfo();
    this.getChatList(1);
  },
  methods: {
    onmessage(e) {},
    initTableHeight() {
      this.dom1 = document.getElementById("dom1").offsetHeight;
      this.dom2 = document.getElementById("dom2").offsetHeight;
      this.dom3 = document.getElementById("dom3").offsetHeight;
    },
    // 获取推广分析基础信息
    async getBaseInfo() {
      const res = await Service.getBaseInfo();
      this.channelArr = res.data.channelArr;
    },
    // 搜索操作
    retrieval() {
      this.getChatList(1);
    },
    async getChatList(page) {
      this.listLoading = true;
      var time_range0 = this.time_range ? [...this.time_range] : [];
      if (time_range0.length) {
        time_range0[0] = time_range0[0] / 1000;
        time_range0[1] = time_range0[1] / 1000;
      }
      const res = await Service.getChatList({
        page,
        search: this.search,
        time_range: time_range0,
        send_user: this.send_user,
        accept_user: this.accept_user,
        channel_id: this.channel_id,
      });
      // console.log("聊天记录列表", res);
      this.page = page;
      this.total = res.data.list.total;
      this.tableData = res.data.list.data;
      this.listLoading = false;
    },
    handleCurrentChange(val) {
      this.getChatList(val);
      // console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style scoped>
.body {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.head {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 15px;
}

.head > div {
  display: flex;
  align-items: center;
}

.head > div > div:first-child {
  margin-right: 10px;
  font-size: 14px;
  /* margin-bottom: 10px; */
}

.w300 {
  width: 300px;
}

.w222 /deep/ .el-form-item__content {
  width: 222px !important;
  flex: initial !important;
}

.form_cell_title {
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid #e0e9f5;
  box-sizing: border-box;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.form_cell {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.form_cell /deep/.el-form-item {
  width: 50% !important;
}
</style>
