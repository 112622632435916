<template>
  <div>
    <pageTitle :title="title"></pageTitle>
    <div class="bb pt20 pl20">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="推广分析" name="first">
          <div class="head bb p20 flex fw_w">
            <div>
              <div>时间</div>
              <div>
                <!-- <el-date-picker format="YYYY-MM-DD"
                                value-format="x"
                                size="large"
                                v-model="time_range"
                                type="datetimerange"
                                align="right"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                </el-date-picker> -->
                <el-date-picker
                  :size="size"
                  style="width: 360px"
                  v-model="time_range"
                  value-format="x"
                  type="datetimerange"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                />
              </div>
            </div>
            <div>
              <div>账户</div>
              <div>
                <el-select
                  v-model="channel_ids"
                  multiple
                  :size="size"
                  collapse-tags
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in channelArr"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div>
              <div>来源</div>
              <div>
                <el-select
                  style="width: 140px"
                  v-model="url_id"
                  :size="size"
                  collapse-tags
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in urlArr"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div>
              <div>组别</div>
              <div>
                <el-select
                  style="width: 120px"
                  :size="size"
                  v-model="group_id"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in group_arr"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div>
              <el-button @click="retrieval" type="primary">搜 索</el-button>
            </div>
          </div>
          <div class="bb pl20 pr20" v-loading="listLoading">
            <div class="mb20">
              <el-table
                :data="tableData"
                height="55vh"
                border
                style="width: 100%"
              >
                <el-table-column
                  fixed
                  label="用户名"
                  width="130"
                  prop="service_name"
                >
                </el-table-column>
                <el-table-column label="数据" :width="dataWidth">
                  <template #default="scope">
                    <div class="flex">
                      <div
                        class="data_div"
                        v-for="(item, index) in scope.row.info"
                        :key="index"
                      >
                        <div>来源：{{ item.url }}</div>
                        <div>渠道：{{ item.channel_name }}</div>
                        <div>次数：{{ item.count }}</div>
                        <div>有效会话：{{ item.valid_chat_count }}</div>
                        <div>主动发送：{{ item.service_chat_count }}</div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="footer">
              <div>合计</div>
              <div
                class="flex fd_c tal"
                v-for="(item, index) in allList"
                :key="index"
              >
                <div class="fb">渠道：{{ item.name }}</div>
                <div class="fb">次数：{{ item.count }}</div>
                <div class="fb">未分配数：{{ item.un_service }}</div>
                <div class="fb">有效会话：{{ item.valid_chat_count }}</div>
                <div class="fb">主动发送：{{ item.service_chat_count }}</div>
              </div>
            </div>
            <!-- <div>
        <el-pagination @current-change="handleCurrentChange"
                       :current-page="page"
                       :page-size="15"
                       layout="total, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
      </div> -->
          </div>
        </el-tab-pane>
        <el-tab-pane label="邀请有奖" name="second">
          <div class="flex">
            <div style="width: calc(100% - 600px)">
              <div class="head bb p20 flex fw_w">
                <div>
                  <div>邀请人</div>
                  <div class="w200">
                    <el-input
                      clearable
                      :size="size"
                      v-model="recommend_search"
                      placeholder="请输入昵称"
                    ></el-input>
                  </div>
                </div>
                <div>
                  <div>被邀请人</div>
                  <div class="w200">
                    <el-input
                      clearable
                      :size="size"
                      v-model="search"
                      placeholder="请输入昵称/手机号"
                    ></el-input>
                  </div>
                </div>
                <div>
                  <el-radio-group
                    v-model="floor_text"
                    :size="size"
                    @change="floor = floor_text == '一级' ? 1 : 2"
                  >
                    <el-radio-button label="一级" />
                    <el-radio-button label="二级" />
                  </el-radio-group>
                </div>
                <div>
                  <div>激活时间</div>
                  <div>
                    <el-date-picker
                      format="YYYY-MM-DD"
                      value-format="x"
                      :size="size"
                      v-model="time_range"
                      type="daterange"
                      align="right"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                    >
                    </el-date-picker>
                  </div>
                </div>
                <div>
                  <el-button @click="retrieval" type="primary">搜 索</el-button>
                </div>
              </div>
              <div v-loading="listLoading">
                <div class="mb20">
                  <el-table
                    :data="tableData1"
                    height="55vh"
                    style="width: 100%"
                  >
                    <el-table-column label="昵称" width="130" prop="nickname">
                    </el-table-column>
                    <el-table-column label="手机号" width="120" align="center">
                      <template #default="scope">
                        <div v-if="currentShowMobileIndex == scope.$index">
                          {{ scope.row.mobile }}
                        </div>
                        <div v-else>{{ scope.row.mobileText }}</div>
                        <el-button
                          type="primary"
                          size="small"
                          @click="currentShowMobileIndex = scope.$index"
                          >查看</el-button
                        >
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="激活时间"
                      width="170"
                      prop="active_time"
                    >
                    </el-table-column>
                    <el-table-column label="邀请人" width="">
                      <template #default="scope">
                        <div class="flex at_c fz12">
                          <img
                            class="avatar"
                            :src="scope.row.recommend.avatar"
                            alt=""
                          />
                          <div>{{ scope.row.recommend.nickname }}</div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="归属" width="">
                      <template #default="scope">
                        <div class="flex at_c fz12">
                          <img
                            class="avatar"
                            :src="scope.row.service.avatar"
                            alt=""
                          />
                          <div>{{ scope.row.service.nickname }}</div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="IP信息"
                      width=""
                      show-overflow-tooltip
                    >
                      <template #default="scope">
                        <div class="flex at_c fz12">
                          <span class="mr5">{{
                            scope.row.ip_info.Province
                          }}</span>
                          <span class="mr5">{{ scope.row.ip_info.City }}</span>
                          <span>{{ scope.row.ip_info.County }}</span>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div>
                  <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page="page"
                    :page-size="15"
                    layout="total, prev, pager, next, jumper"
                    :total="total"
                  >
                  </el-pagination>
                </div>
              </div>
            </div>

            <div class="bb pl30 pr30 flex fd_c">
              <div class="title1">排行榜</div>
              <div class="head bb p30 flex fw_w">
                <div>
                  <div>时间范围</div>
                  <div>
                    <el-date-picker
                      format="YYYY-MM-DD"
                      value-format="x"
                      :size="size"
                      v-model="time_range1"
                      type="daterange"
                      align="right"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                    >
                    </el-date-picker>
                  </div>
                </div>
                <div>
                  <el-button @click="getRecommendRank()" type="primary"
                    >搜 索</el-button
                  >
                </div>
              </div>
              <div class="mb20 paihangbang" v-loading="listLoading1">
                <el-table
                  :data="rankList"
                  height="55vh"
                  style="width: 100%"
                  :default-sort="{ prop: 'first_count', order: 'descending' }"
                  show-summary
                  :summary-method="getSummaries"
                >
                  <el-table-column label="排名" width="80" align="center">
                    <template #default="scope">
                      <img
                        class="icon_rank"
                        v-if="scope.$index + 1 == 1"
                        src="@/assets/img/rank1.png"
                        alt=""
                      />
                      <img
                        class="icon_rank"
                        v-else-if="scope.$index + 1 == 2"
                        src="@/assets/img/rank2.png"
                        alt=""
                      />
                      <img
                        class="icon_rank"
                        v-else-if="scope.$index + 1 == 3"
                        src="@/assets/img/rank3.png"
                        alt=""
                      />
                      <span class="rank_num" v-else>{{
                        scope.$index + 1
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="用户名" width="170">
                    <template #default="scope">
                      <div class="flex at_c fz12">
                        <img
                          class="avatar"
                          :src="scope.row.user.avatar"
                          alt=""
                        />
                        <div>{{ scope.row.user.nickname }}</div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="一级"
                    width="90"
                    sortable
                    prop="first_count"
                    align="center"
                  >
                  </el-table-column>
                  <el-table-column
                    label="二级"
                    width="90"
                    sortable
                    prop="second_count"
                    align="center"
                  >
                  </el-table-column>
                  <el-table-column
                    label="邀请人数"
                    width="110"
                    sortable
                    prop="total"
                    align="center"
                  >
                  </el-table-column>
                  <el-table-column
                    label="下载次数"
                    width="110"
                    sortable
                    prop="downloadTotal"
                    align="center"
                  >
                  </el-table-column>
                  <el-table-column
                    label="预估总收益"
                    width="130"
                    sortable
                    prop="money"
                    align="center"
                  >
                  </el-table-column>
                </el-table>
              </div>
              <!-- <div class="footer">
                <div>合计</div>
                <div class="flex fd_c tal">
                  <div class="fb">一级：{{rankListTotal.firstCount}}</div>
                </div>
                <div class="flex fd_c tal">
                  <div class="fb">二级：{{rankListTotal.secondCount}}</div>
                </div>
                <div class="flex fd_c tal">
                  <div class="fb">邀请人数：{{rankListTotal.total}}</div>
                </div>
                <div class="flex fd_c tal">
                  <div class="fb">下载次数：{{rankListTotal.downloadTotalCount}}</div>
                </div>
                <div class="flex fd_c tal">
                  <div class="fb">预估总收益：{{rankListTotal.totalMoney}}</div>
                </div>
              </div> -->
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import * as Service from "@/api/dataStatistics";
import pageTitle from "@/common/pageTitle.vue";
export default {
  components: {
    pageTitle,
  },
  data() {
    return {
      title: "数据统计",
      size: "medium",
      search: "", // 关键词
      time_range: [
        new Date(new Date().toLocaleDateString()).getTime(),
        new Date(new Date().toLocaleDateString()).getTime() +
          60 * 60 * 24 * 1000,
      ], // 时间
      tableData: [],
      page: 1,
      total: 0,
      listLoading: false,
      channelArr: [],
      dataWidth: "",
      channel_ids: [],
      urlArr: [],
      url_id: null,

      activeName: "first",
      tableData1: [],
      rankList: [],
      listLoading1: false,
      recommend_search: "",
      rankListTotal: {
        firstCount: 0,
        secondCount: 0,
        total: 0,
        downloadTotalCount: 0,
        totalMoney: 0,
      },
      time_range1: [], // 时间
      floor_text: "一级", // 级别
      floor: 1, // 级别

      group_arr: [
        {
          id: -1,
          name: "全部",
        },
      ], // 组别列表
      group_id: -1,

      currentShowMobileIndex: null, // 当前显示的手机号
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    if (this.activeName == "first") {
      this.getBaseInfo();
      this.getList(1);
    } else {
      this.getUserList(1);
      this.getRecommendRank();
    }
    this.getServiceBaseInfo();
  },
  methods: {
    onmessage(e) {},
    // 获取客服基础配置
    async getServiceBaseInfo() {
      const res = await Service.getServiceBaseInfo();
      this.group_arr = this.group_arr.concat(res.data.group_arr);
    },
    // tab切换
    handleClick(tab, event) {
      this.search = "";
      this.time_range = [];
      this.time_range1 = [];
      this.tableData = [];
      this.tableData1 = [];
      this.page = 1;
      this.total = 0;
      this.listLoading = false;
      this.channel_ids = [];
      this.url_id = null;
      this.urlArr = [];
      this.recommend_search = "";
      if (this.activeName == "first") {
        this.getBaseInfo();
        this.getList(1);
      } else {
        this.getUserList(1);
        this.getRecommendRank();
      }
    },
    // 获取邀请有奖列表
    async getUserList(page) {
      this.listLoading = true;
      var time_range0 = this.time_range ? [...this.time_range] : [];
      if (time_range0.length) {
        time_range0[0] = time_range0[0] / 1000;
        time_range0[1] = time_range0[1] / 1000;
      }
      const res = await Service.getUserList({
        page,
        search: this.search,
        time_range: time_range0,
        floor: this.floor,
        recommend_search: this.recommend_search,
      });
      this.page = page;
      var tableData1 = res.data.data;
      const reg = /(\d{3})\d{4}(\d{4})/;
      for (const item of tableData1) {
        item.mobileText = item.mobile.replace(reg, "$1****$2");
      }
      this.tableData1 = tableData1;
      this.total = res.data.total;
      this.listLoading = false;
    },
    // 获取有奖排行
    async getRecommendRank() {
      this.listLoading1 = true;
      var time_range0 = this.time_range1 ? [...this.time_range1] : [];
      if (time_range0.length) {
        time_range0[0] = time_range0[0] / 1000;
        time_range0[1] = time_range0[1] / 1000;
      }
      const res = await Service.recommendRank({
        time_range: time_range0,
      });
      this.rankList = res.data.list;
      this.rankListTotal = res.data.total;
      this.listLoading1 = false;
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        console.log("column", column);
        console.log("index", index);
        console.log("-----------");
        if (index === 0) {
          sums[0] = "合计";
          return;
        }
        if ([1].includes(index)) {
          sums[index] = "-";
          return;
        }
        if ([2, 3, 4, 5, 6].includes(index)) {
          const values = data.map((item) => Number(item[column.property]));
          var val = 0;
          for (const item of values) {
            val += item;
          }
          console.warn("values", values);
          sums[index] = val;
          return;
        }
      });

      return sums;
    },
    // 获取推广分析基础信息
    async getBaseInfo() {
      const res = await Service.getBaseInfo();
      this.channelArr = res.data.channelArr;
      this.urlArr = res.data.urlArr;
    },
    // 推广分析搜索操作
    retrieval() {
      console.log(this.time_range);
      if (this.activeName == "first") {
        this.getList(1);
      } else {
        this.getUserList(1);
      }
    },
    // 获取推广分析列表
    async getList(page) {
      this.listLoading = true;
      var time_range0 = this.time_range ? [...this.time_range] : [];
      if (time_range0.length) {
        time_range0[0] = time_range0[0] / 1000;
        time_range0[1] = time_range0[1] / 1000;
      }
      var channel_ids = this.channel_ids;
      var url_id = this.url_id;
      const res = await Service.touristStatistics({
        page,
        time_range: time_range0,
        channel_ids,
        url_id,
        group_id: this.group_id,
      });
      this.page = page;
      this.dataWidth = res.data.serviceList.length
        ? res.data.serviceList[0].info.length * 270
        : 270;
      this.tableData = res.data.serviceList;
      this.allList = res.data.allList;
      this.listLoading = false;
    },
    // 切换分页
    handleCurrentChange(val) {
      this.getUserList(val);
      // console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style scoped>
.head {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 15px;
}

.head > div {
  display: flex;
  align-items: center;
}

.head > div > div:first-child {
  margin-right: 10px;
  font-size: 14px;
  /* margin-bottom: 10px; */
}

.w300 {
  width: 300px;
}

.w200 {
  width: 200px;
}

.w222 /deep/ .el-form-item__content {
  width: 222px !important;
  flex: initial !important;
}

.form_cell_title {
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid #e0e9f5;
  box-sizing: border-box;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.form_cell {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.form_cell /deep/.el-form-item {
  width: 50% !important;
}

.data_div {
  width: 270px;
  box-sizing: border-box;
  padding: 0 20px;
}

.footer {
  background-color: #f5f7fa;
  color: #606266;
  border: 1px solid #ebeef5;
  font-size: 14px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.footer > div {
  min-width: 100px;
  padding: 12px 10px;
  box-sizing: border-box;
}

.footer > div:first-child {
}

.footer > div:not(:last-child) {
  border-right: 1px solid #ebeef5;
}

.avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 10px;
  box-sizing: border-box;
  border: 1px solid #dedede;
}

.icon_rank {
  width: 46px;
  height: 46px;
}

.rank_num {
  font-size: 22px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #4571e7;
  line-height: 30px;
}

.title1 {
  font-size: 20px;
  font-weight: bold;
  padding: 10px 0;
}

.paihangbang /deep/ .el-table__footer td:nth-child(1) {
  width: 80px;
}

.paihangbang /deep/ .el-table__footer td:nth-child(2) {
  width: 170px;
}

.paihangbang /deep/ .el-table__footer td:nth-child(3) {
  width: 90px;
}

.paihangbang /deep/ .el-table__footer td:nth-child(4) {
  width: 90px;
}

.paihangbang /deep/ .el-table__footer td:nth-child(5) {
  width: 110px;
}

.paihangbang /deep/ .el-table__footer td:nth-child(6) {
  width: 110px;
}

.paihangbang /deep/ .el-table__footer td:nth-child(7) {
  width: 130px;
}
</style>
