<template>
  <div class="body">
    <pageTitle id="dom1" :title="title"></pageTitle>
    <div id="dom2" class="head bb p20 flex fw_w">
      <div>
        <div>关键词</div>
        <div style="width: 240px">
          <el-input
            clearable
            :size="size"
            v-model="search"
            placeholder="请输入手机号"
          ></el-input>
        </div>
      </div>
      <div>
        <div>时间</div>
        <div>
          <el-date-picker
            style="width: 240px"
            format="YYYY-MM-DD"
            value-format="x"
            :size="size"
            v-model="time_range"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </div>
      <div>
        <el-button @click="retrieval" type="primary">搜 索</el-button>
      </div>
    </div>
    <div
      :style="{ height: `calc(100% - (${dom1 + 'px'} + ${dom2 + 'px'}))` }"
      v-loading="listLoading"
    >
      <div
        class="bb pl20 pr20"
        :style="{ height: `calc(100% - (${dom3 + 'px'}))` }"
      >
        <el-table :data="tableData" height="100%" style="width: 100%">
          <el-table-column label="ID" width="100" prop="id"> </el-table-column>
          <el-table-column label="手机号" width="120" align="center">
            <template #default="scope">
              <div v-if="currentShowMobileIndex == scope.$index">
                {{ scope.row.mobile }}
              </div>
              <div v-else>{{ scope.row.mobileText }}</div>
              <el-button
                type="primary"
                size="small"
                @click="currentShowMobileIndex = scope.$index"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <el-table-column label="时间" width="180" prop="createtime">
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="URL"
            width=""
            prop="user_info.url"
          >
          </el-table-column>
          <!-- <el-table-column label="来源"
                           width=""
                           prop="user_info.referer">
          </el-table-column> -->
          <el-table-column label="渠道" width="" prop="channel_name">
          </el-table-column>
          <el-table-column label="客户经理归属" width="130" prop="service_name">
          </el-table-column>
          <el-table-column label="咨询信息" show-overflow-tooltip prop="info">
          </el-table-column>
          <el-table-column label="备注(点击填写)" width="">
            <template #default="scope">
              <el-input
                @blur="modifyContent(scope.row.id, scope.row.remark)"
                v-model="scope.row.remark"
                placeholder="请输入内容"
              ></el-input>
            </template>
          </el-table-column>
          <!-- <el-table-column label="权限开关"
                           width="110">
            <template #default="scope">
              <el-tooltip :content="'权限:' + (scope.row.auth==1?'开':'关')"
                          placement="top">
                <el-switch @click="changeAuth(scope.$index,scope.row)"
                           :value="scope.row.auth"
                           :disabled="scope.row.authDisabled"
                           active-color="#13ce66"
                           inactive-color="#ff4949"
                           :active-value="1"
                           :inactive-value="0">
                </el-switch>
              </el-tooltip>
            </template>
          </el-table-column> -->
          <el-table-column label="是否拨打" width="110">
            <template #default="scope">
              <el-tooltip
                :content="'是否拨打:' + (scope.row.is_call == 1 ? '是' : '否')"
                placement="top"
              >
                <el-switch
                  @click="changeCall(scope.$index, scope.row)"
                  :value="scope.row.is_call"
                  :disabled="scope.row.callDisabled"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
                >
                </el-switch>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="是否注册" width="110">
            <template #default="scope">
              <el-tag
                v-if="scope.row.is_register == 1"
                type="success"
                effect="dark"
              >
                是
              </el-tag>
              <el-tag v-else type="danger" effect="dark"> 否 </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="身份" width="100" prop="user_info.type">
          </el-table-column>
        </el-table>
      </div>
      <div id="dom3" class="bb p20">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="15"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as Service from "../api/phoneNumberList";
import pageTitle from "../common/pageTitle.vue";
export default {
  components: {
    pageTitle,
  },
  data() {
    return {
      title: "手机号列表",
      size: "medium",
      search: "", // 关键词
      time_range: [], // 时间
      send_user: null, // 发送人
      accept_user: null, // 接收人
      tableData: [],
      page: 1,
      total: 0,
      listLoading: false,

      currentShowMobileIndex: null, // 当前显示的手机号

      dom1: 0,
      dom2: 0,
      dom3: 0,
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.initTableHeight();
    this.getList(1);
  },
  methods: {
    onmessage(e) {},
    initTableHeight() {
      this.dom1 = document.getElementById("dom1").offsetHeight;
      this.dom2 = document.getElementById("dom2").offsetHeight;
      this.dom3 = document.getElementById("dom3").offsetHeight;
    },
    // 搜索操作
    retrieval() {
      this.getList(1);
    },
    // 获取列表
    async getList(page) {
      this.listLoading = true;
      var time_range0 = this.time_range ? [...this.time_range] : [];
      if (time_range0.length) {
        time_range0[0] = time_range0[0] / 1000;
        time_range0[1] = time_range0[1] / 1000;
      }
      const res = await Service.mobileList({
        page,
        search: this.search,
        time_range: time_range0,
        // send_user: this.send_user,
        // accept_user: this.accept_user,
      });
      // console.log("聊天记录列表", res);
      this.page = page;
      this.total = res.data.total;
      var tableData = res.data.data;
      const reg = /(\d{3})\d{4}(\d{4})/;
      for (const item of tableData) {
        item.authDisabled = false;
        item.callDisabled = false;
        item.mobileText = item.mobile.replace(reg, "$1****$2");
      }
      this.tableData = tableData;
      this.listLoading = false;
    },
    // 切换分页
    handleCurrentChange(val) {
      this.getList(val);
      // console.log(`当前页: ${val}`);
    },
    // 修改权限
    changeAuth(index, row) {
      if (!row.authDisabled) {
        this.changeAuthApi(index, row);
      }
      this.tableData[index].authDisabled = true;
      setTimeout(() => {
        this.tableData[index].authDisabled = false;
      }, 800);
    },
    async changeAuthApi(index, row) {
      const res = await Service.setAuth({
        id: row.id,
        auth: row.auth == 1 ? 0 : 1,
      });
      this.getList(this.page);
      this.$message.success(res.msg);
    },
    // 修改是否拨打
    changeCall(index, row) {
      if (!row.callDisabled) {
        this.changeCallApi(index, row);
      }
      this.tableData[index].callDisabled = true;
      setTimeout(() => {
        this.tableData[index].callDisabled = false;
      }, 800);
    },
    async changeCallApi(index, row) {
      const res = await Service.setCall({
        id: row.id,
        is_call: row.is_call == 1 ? 0 : 1,
      });
      this.getList(this.page);
      this.$message.success(res.msg);
    },
    // 备注
    async modifyContent(id, remark) {
      const res = await Service.changeRemark({
        id,
        remark,
      });
      this.getList(this.page);
      this.$message.success(res.msg);
    },
  },
};
</script>

<style scoped>
.body {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.head {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 15px;
}

.head > div {
  display: flex;
  align-items: center;
}

.head > div > div:first-child {
  margin-right: 10px;
  font-size: 14px;
  /* margin-bottom: 10px; */
}

.w300 {
  width: 300px;
}

.w222 /deep/ .el-form-item__content {
  width: 222px !important;
  flex: initial !important;
}

.form_cell_title {
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid #e0e9f5;
  box-sizing: border-box;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.form_cell {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.form_cell /deep/.el-form-item {
  width: 50% !important;
}
</style>
