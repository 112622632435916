import request from "../utils/request";

//我的游客列表
export const getVisitorUserList = (query) => {
  return request({
    url: "/service/info/getVisitorUserList",
    method: "POST",
    data: query,
  });
};
//我的会员列表
export const getUserList = (query) => {
  return request({
    url: "/service/info/getUserList",
    method: "POST",
    data: query,
  });
};
//添加假人
export const fillUser = (query) => {
  return request({
    url: "/service/info/fillUser",
    method: "POST",
    data: query,
  });
};
//修改会员个人信息
export const profile = (query) => {
  return request({
    url: "/service/info/profile",
    method: "POST",
    data: query,
  });
};
//获取会员圈子列表
export const getUserCircleList = (query) => {
  return request({
    url: "/service/info/getUserCircleList",
    method: "POST",
    data: query,
  });
};
//会员加入圈子
export const joinCircle = (query) => {
  return request({
    url: "/service/info/joinCircle",
    method: "POST",
    data: query,
  });
};
//会员离开圈子
export const leaveCircle = (query) => {
  return request({
    url: "/service/info/leaveCircle",
    method: "POST",
    data: query,
  });
};
//修改会员V粒
export const changeMoney = (query) => {
  return request({
    url: "/service/info/changeMoney",
    method: "POST",
    data: query,
  });
};