<template>
  <div class="body">
    <pageTitle id="dom1" :title="title"></pageTitle>
    <div id="dom2" class="head bb p20 flex fw_w">
      <div>
        <div>关键词</div>
        <div style="width: 240px">
          <el-input
            clearable
            :size="size"
            v-model="keywords"
            placeholder="请输入关键词"
          ></el-input>
        </div>
      </div>
      <div>
        <div>时间</div>
        <div>
          <el-date-picker
            style="width: 240px"
            format="YYYY-MM-DD"
            value-format="x"
            :size="size"
            v-model="time_range"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </div>
      <div>
        <div>有效会话时间</div>
        <div>
          <el-date-picker
            style="width: 240px"
            format="YYYY-MM-DD"
            value-format="x"
            :size="size"
            v-model="valid_time_range"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </div>
      <div>
        <div>归属</div>
        <div style="width: 140px">
          <el-select
            filterable
            :size="size"
            v-model="service_id"
            placeholder="请选择"
          >
            <el-option
              v-for="item in serviceArr"
              :key="item.id"
              :label="item.nickname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div>
        <div>链接</div>
        <div>
          <el-select filterable :size="size" v-model="url" placeholder="请选择">
            <el-option label="全部" value=""> </el-option>
            <el-option
              v-for="item in urlArr"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div>
        <el-button @click="retrieval" type="primary">搜 索</el-button>
      </div>
    </div>
    <div
      :style="{ height: `calc(100% - (${dom1 + 'px'} + ${dom2 + 'px'}))` }"
      v-loading="userListLoading"
    >
      <div
        class="bb pl20 pr20"
        :style="{ height: `calc(100% - (${dom3 + 'px'}))` }"
      >
        <el-table :data="tableData" height="100%" style="width: 100%">
          <el-table-column
            show-overflow-tooltip
            label="昵称"
            width="120"
            prop="nickname"
          >
          </el-table-column>
          <el-table-column label="来源" width="200" prop="referer">
          </el-table-column>
          <el-table-column label="IP" width="150" prop="joinip">
          </el-table-column>
          <el-table-column label="注册时间" width="170" prop="createtime">
          </el-table-column>
          <el-table-column label="归属" width="120">
            <template #default="scope">
              {{ scope.row.service.id ? scope.row.service.nickname : "-" }}
            </template>
          </el-table-column>
          <el-table-column label="计划" width="100" prop="jihua">
          </el-table-column>
          <el-table-column label="关键词id" width="100" prop="key_id">
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="关键词"
            width=""
            prop="keywords"
          >
          </el-table-column>
        </el-table>
      </div>
      <div id="dom3" class="bb p20">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="15"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as Service from "@/api/myClient";
import * as ServiceIndex from "@/api/index";
import pageTitle from "@/common/pageTitle.vue";
export default {
  components: {
    pageTitle,
  },
  data() {
    return {
      title: "游客列表",
      size: "medium",
      keywords: "", // 关键词
      time_range: [], // 时间范围
      valid_time_range: [], // 有效会话时间范围
      service_id: 0, // 0全部客服
      serviceArr: [
        {
          id: 0,
          nickname: "全部",
        },
      ],
      url: "",
      urlArr: [],
      userListLoading: false, // 会员列表加载状态
      tableData: [],
      page: 1,
      total: 0,
      teacherList: [], // 研究员列表
      serviceList: [], // 客服列表
      serviceId: null, // 当前账号客服id

      dom1: 0,
      dom2: 0,
      dom3: 0,
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.initTableHeight();
    this.getBaseInfo();
    this.getUserInfo();
    this.getVisitorUserList(1);
  },
  methods: {
    onmessage(e) {},
    initTableHeight() {
      this.dom1 = document.getElementById("dom1").offsetHeight;
      this.dom2 = document.getElementById("dom2").offsetHeight;
      this.dom3 = document.getElementById("dom3").offsetHeight;
    },
    // 搜索操作
    retrieval() {
      this.getVisitorUserList(1);
    },
    // 获取基础信息
    async getBaseInfo() {
      const res = await ServiceIndex.baseInfo();
      this.teacherList = res.data.teacherList;
      this.serviceList = res.data.serviceList;
      this.serviceArr = this.serviceArr.concat(res.data.serviceList);
      this.urlArr = res.data.urlArr;
    },
    // 获取账号信息
    async getUserInfo() {
      const res = await ServiceIndex.getServiceIndex();
      this.serviceId = res.data.userinfo.user_id;
    },
    // 获取我的会员列表
    async getVisitorUserList(page) {
      this.userListLoading = true;
      var time_range0 = this.time_range ? [...this.time_range] : [];
      var valid_time_range0 = this.valid_time_range
        ? [...this.valid_time_range]
        : [];
      if (time_range0.length) {
        time_range0[0] = time_range0[0] / 1000;
        time_range0[1] = time_range0[1] / 1000;
      }
      if (valid_time_range0.length) {
        valid_time_range0[0] = valid_time_range0[0] / 1000;
        valid_time_range0[1] = valid_time_range0[1] / 1000;
      }
      const res = await Service.getVisitorUserList({
        page,
        keywords: this.keywords,
        time_range: time_range0,
        valid_time_range: valid_time_range0,
        service_id: this.service_id,
        url: this.url,
      });
      this.page = page;
      this.total = res.data.total;
      this.tableData = res.data.data;
      this.userListLoading = false;
    },
    handleCurrentChange(val) {
      this.getVisitorUserList(val);
    },
  },
};
</script>

<style scoped>
.body {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.head {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 15px;
}

.head > div {
  display: flex;
  align-items: center;
}

.head > div > div:first-child {
  margin-right: 10px;
  font-size: 14px;
  /* margin-bottom: 10px; */
}

.w300 {
  width: 300px;
}

.w222 /deep/ .el-form-item__content {
  width: 222px !important;
  flex: initial !important;
}

.form_cell_title {
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid #e0e9f5;
  box-sizing: border-box;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.form_cell {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.w202 /deep/.el-input__inner {
  width: 202px !important;
}

.w202 .date /deep/.el-input__suffix {
  right: 33px !important;
}

.avatar {
  width: 45px;
  height: 45px;
}
</style>
