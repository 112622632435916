<template>
  <div class="body">
    <pageTitle id="dom1" :title="title"></pageTitle>
    <div id="dom2" class="head bb p20 flex fw_w">
      <div>
        <div>关键词</div>
        <div style="width: 240px">
          <el-input
            clearable
            :size="size"
            v-model="search"
            placeholder="请输入昵称/手机号/真实姓名"
          ></el-input>
        </div>
      </div>
      <div>
        <div>最后圈子登入时间</div>
        <div>
          <el-date-picker
            style="width: 240px"
            format="YYYY-MM-DD"
            value-format="x"
            :size="size"
            v-model="circle_time_range"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </div>
      <!-- <div>
        <div>会员等级</div>
        <div>
          <el-select filterable
                     :size="size"
                     v-model="level"
                     placeholder="请选择">
            <el-option v-for="item in levelList"
                       :key="item.id"
                       :label="item.label"
                       :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div>
        <div>SVIP等级</div>
        <div>
          <el-select filterable
                     :size="size"
                     v-model="money_level"
                     placeholder="请选择">
            <el-option label="全部"
                       :value="0">
            </el-option>
            <el-option v-for="item in moneyLevelList"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div> -->
      <div v-if="is_service_admin == 1">
        <div>归属</div>
        <div style="width: 140px">
          <el-select
            filterable
            :size="size"
            v-model="service_id"
            placeholder="请选择"
          >
            <el-option
              v-for="item in serviceArr"
              :key="item.id"
              :label="item.nickname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- <div>
        <div>IDAS权限</div>
        <div>
          <el-select filterable
                     :size="size"
                     v-model="is_push"
                     placeholder="请选择">
            <el-option v-for="item in is_pushArr"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div> -->
      <div>
        <div>排序（最后圈子登入时间）</div>
        <div>
          <el-radio-group v-model="order" :size="size">
            <el-radio-button label="降序" />
            <el-radio-button label="升序" />
          </el-radio-group>
        </div>
      </div>
      <div>
        <div>是否实盘</div>
        <div style="width: 120px">
          <el-select
            v-model="is_firm_offer"
            class="m-2"
            placeholder=""
            :size="size"
          >
            <el-option label="全部" :value="0" />
            <el-option label="实盘" :value="1" />
            <el-option label="非实盘" :value="2" />
          </el-select>
        </div>
      </div>
      <!-- <div>
        <div>来源</div>
        <div>
          <el-select size="large"
                     v-model="value4"
                     placeholder="请选择">
            <el-option v-for="item in options"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div>
        <div>类型</div>
        <div>
          <el-select size="large"
                     v-model="value4"
                     placeholder="请选择">
            <el-option v-for="item in options"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div> -->
      <!-- <div>
        <div>部门</div>
        <div>
          <el-select size="large"
                     v-model="value4"
                     placeholder="请选择">
            <el-option v-for="item in options"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div> -->
      <div>
        <el-button @click="retrieval" type="primary">搜 索</el-button>
      </div>
    </div>
    <div
      :style="{ height: `calc(100% - (${dom1 + 'px'} + ${dom2 + 'px'}))` }"
      v-loading="userListLoading"
    >
      <div
        class="bb pl20 pr20"
        :style="{ height: `calc(100% - (${dom3 + 'px'}))` }"
      >
        <el-table :data="tableData" height="100%" style="width: 100%">
          <el-table-column
            show-overflow-tooltip
            label="昵称"
            width="120"
            prop="nickname"
          >
          </el-table-column>
          <el-table-column label="手机号" width="120" align="center">
            <template #default="scope">
              <div v-if="currentShowMobileIndex == scope.$index">
                {{ scope.row.mobile }}
              </div>
              <div v-else>{{ scope.row.mobileText }}</div>
              <el-button
                type="primary"
                size="small"
                @click="currentShowMobileIndex = scope.$index"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <!-- <el-table-column label="真实姓名"
                           width=""
                           prop="name">
            </el-table-column> -->
          <!-- <el-table-column label="IP / 注册时间 / 会员等级 / SVIP等级"
                             width="260">
              <template #default="scope">
                <div>{{scope.row.joinip}}</div>
                <div>{{scope.row.createtime}}</div>
                <div>会员等级：{{scope.row.level.levelName}}</div>
                <div>SVIP等级：{{scope.row.level.moneyLevelName}}</div>
              </template>
            </el-table-column> -->
          <!-- <el-table-column label="IP"
                             width="150"
                             prop="joinip">
            </el-table-column>
            <el-table-column label="注册时间"
                             width="170"
                             prop="createtime">
            </el-table-column>
            <el-table-column label="等级"
                             width="130"
                             prop="level.levelName">
            </el-table-column> -->
          <!-- <el-table-column label="IDAS权限"
                             width="130">
              <template #default="scope">
                {{scope.row.is_push==1?'是':'否'}}
              </template>
            </el-table-column> -->
          <!-- <el-table-column label="研究员"
                             width="140">
              <template #default="scope">
                {{scope.row.teacher.id?scope.row.teacher.teacher_name:'-'}}
              </template>
            </el-table-column> -->
          <!-- <el-table-column label="部门"
                           width=""
                           prop="name">
          </el-table-column> -->
          <el-table-column label="归属" width="120">
            <template #default="scope">
              {{ scope.row.service.id ? scope.row.service.nickname : "-" }}
            </template>
          </el-table-column>
          <!-- <el-table-column label="V粒"
                             width="130"
                             prop="money">
            </el-table-column> -->
          <el-table-column label="实盘信息" width="130">
            <template #default="scope">
              {{ scope.row.offerInfo.info.name }}<br />{{
                scope.row.offerInfo.info.phone
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="最后圈子登入时间"
            width="200"
            prop="circle_time"
          >
          </el-table-column>
          <el-table-column
            label="最新一次入圈天数"
            width="140"
            prop="circle_day"
          >
          </el-table-column>
          <el-table-column label="期末权益" width="200">
            <template #default="scope">
              <div
                v-for="(item, index) in scope.row.offerInfo.funds"
                :key="index"
              >
                <div>天数：{{ item.dormancy_days }}</div>
                <div>权益：{{ item.last_money }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="备注"
            width=""
            prop="bio"
          >
          </el-table-column>

          <!-- <el-table-column label="操作"
                             width="300"
                             align="center">
              <template #default="scope">
                <el-button v-if="is_service_admin==1"
                           type="danger"
                           size="small"
                           @click="handleMoney(scope.$index, scope.row)">V粒</el-button>
                <el-button type="primary"
                           size="small"
                           @click="handleCircle(scope.$index, scope.row)">圈子管理</el-button>
                <el-button type="primary"
                           size="small"
                           @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
              </template>
            </el-table-column> -->
        </el-table>
      </div>
      <div id="dom3" class="bb p20">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="15"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 添加假人 -->
    <el-dialog width="420px" v-model="addUserFormShow" title="添加假人">
      <el-form :model="addUserForm" ref="addUserForm" :rules="addUserRules">
        <el-form-item
          class="w222"
          size="large"
          label="昵称"
          :label-width="formLabelWidth"
          prop="nickname"
        >
          <el-input v-model="addUserForm.nickname" autocomplete="off" />
        </el-form-item>
        <el-form-item
          class="w222"
          size="large"
          label="时间"
          :label-width="formLabelWidth"
          prop="live_expiration_time"
        >
          <el-date-picker
            v-model="addUserForm.live_expiration_time"
            type="date"
            placeholder="选择等级到期时间"
            format="YYYY-MM-DD"
            value-format="x"
          >
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item size="large"
                      label="权限组"
                      :label-width="formLabelWidth">
          <el-select v-model="form.region"
                     placeholder="请选择">
            <el-option label="Zone No.1"
                       value="shanghai" />
            <el-option label="Zone No.2"
                       value="beijing" />
          </el-select>
        </el-form-item> -->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addUserFormShow = false">取消</el-button>
          <el-button type="primary" @click="submitForm('addUserForm')"
            >确定</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- 编辑资料 -->
    <el-dialog width="680px" v-model="editFormShow" title="编辑资料">
      <el-form :model="editForm" ref="editForm">
        <div>
          <div class="form_cell_title">基础信息</div>
          <div class="form_cell">
            <el-form-item
              size="large"
              label="昵称"
              :label-width="formLabelWidth1"
            >
              <el-input v-model="editForm.nickname" autocomplete="off" />
            </el-form-item>
            <el-form-item
              size="large"
              label="姓名"
              :label-width="formLabelWidth1"
            >
              <el-input v-model="editForm.username" autocomplete="off" />
            </el-form-item>
            <el-form-item
              size="large"
              label="验证手机"
              :label-width="formLabelWidth1"
            >
              <el-input disabled v-model="editForm.mobile" autocomplete="off" />
            </el-form-item>
            <el-form-item
              size="large"
              label="QQ号"
              :label-width="formLabelWidth1"
            >
              <el-input v-model="editForm.qq" autocomplete="off" />
            </el-form-item>
            <el-form-item
              size="large"
              label="登录密码"
              :label-width="formLabelWidth1"
            >
              <el-input
                placeholder="若不修改请留空"
                v-model="editForm.password"
                autocomplete="off"
              />
            </el-form-item>
          </div>
        </div>
        <div>
          <div class="form_cell_title">权限信息</div>
          <div class="form_cell w202">
            <el-form-item
              class="date"
              size="large"
              label="直播"
              :label-width="formLabelWidth1"
            >
              <el-date-picker
                v-model="editForm.live_expiration_time"
                type="date"
                placeholder="选择直播到期时间"
                format="YYYY-MM-DD"
                value-format="x"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              size="large"
              label="客户经理"
              :label-width="formLabelWidth1"
            >
              <el-select
                filterable
                clearable
                size="large"
                v-model="editForm.service_id"
                placeholder="请选择客户经理"
              >
                <el-option
                  v-for="item in serviceList"
                  :key="item.id"
                  :label="item.nickname"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="form_cell w202" v-if="is_service_admin == 1">
            <el-form-item
              class="date"
              size="large"
              label="会员到期时间"
              :label-width="formLabelWidth1"
            >
              <el-date-picker
                v-model="editForm.level_expiration_time"
                type="date"
                placeholder="选择会员等级到期时间"
                format="YYYY-MM-DD"
                value-format="x"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              size="large"
              label="会员等级"
              :label-width="formLabelWidth1"
            >
              <el-select
                filterable
                clearable
                size="large"
                v-model="editForm.level"
                placeholder="请选择会员等级"
              >
                <el-option
                  v-for="item in levelList1"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="form_cell w202" v-if="is_service_admin == 1">
            <el-form-item
              size="large"
              label="IDAS权限"
              :label-width="formLabelWidth1"
            >
              <el-select
                filterable
                clearable
                size="large"
                v-model="editForm.is_push"
                placeholder="请选择IDAS权限"
              >
                <el-option label="否" :value="0"></el-option>
                <el-option label="是" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              size="large"
              label="SVIP等级"
              :label-width="formLabelWidth1"
            >
              <el-select
                filterable
                clearable
                size="large"
                v-model="editForm.money_level"
                placeholder="请选择SVIP等级"
              >
                <el-option
                  v-for="item in moneyLevelList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-form-item
            size="large"
            label="备注"
            :label-width="formLabelWidth1"
          >
            <el-input
              rows="5"
              resize="none"
              type="textarea"
              v-model="editForm.bio"
              autocomplete="off"
            />
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editFormShow = false">取消</el-button>
          <el-button type="primary" @click="submitForm('editForm')"
            >确定</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- 圈子管理 -->
    <el-dialog width="640px" v-model="circleShow" title="圈子管理">
      <div class="flex jc_b">
        <div>
          <div>
            <div class="h500" v-loading="tableLoading">
              <el-table
                row-key="id"
                :data="circleList"
                @selection-change="handleSelectionChange"
                ref="multipleTable"
              >
                <!-- <el-table-column type="selection"
                                 width="40"
                                 reserve-selection /> -->
                <el-table-column align="center" label="头像" width="80">
                  <template #default="scope">
                    <div class="flex at_c jc_c">
                      <img class="avatar" :src="scope.row.avatar" alt="" />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="title"
                  label="名称"
                  width="240"
                  show-overflow-tooltip
                />
                <el-table-column align="center" label="状态" width="140">
                  <template #default="scope">
                    <div class="flex at_c jc_c">
                      <el-button
                        @click="
                          changeCircleStatus(
                            scope.row.teacher_id,
                            scope.row.status
                          )
                        "
                        v-if="scope.row.status"
                        type="primary"
                        >已加入</el-button
                      >
                      <el-button
                        @click="
                          changeCircleStatus(
                            scope.row.teacher_id,
                            scope.row.status
                          )
                        "
                        v-else
                        type="danger"
                        >未加入</el-button
                      >
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="140">
                  <template #default="scope">
                    <div class="flex at_c jc_c">
                      <el-button
                        @click="
                          changeCircleStatus(
                            scope.row.teacher_id,
                            scope.row.status,
                            1
                          )
                        "
                        v-if="scope.row.status"
                        type="danger"
                        >彻底删除</el-button
                      >
                      <div v-if="!scope.row.status">-</div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <!-- <div class="mt10">
            <el-pagination @current-change="handleCurrentChange"
                           v-model:current-page="currentPage"
                           layout="prev, pager, next, jumper"
                           :page-size="15"
                           :total="total">
            </el-pagination>
          </div> -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as Service from "../api/myClient";
import * as ServiceIndex from "../api/index";
import pageTitle from "../common/pageTitle.vue";
export default {
  components: {
    pageTitle,
  },
  data() {
    return {
      title: "圈子记录",
      size: "medium",
      search: "", // 关键词
      circle_time_range: [], // 时间范围
      level: 0, // 0全部 1会员 2注册会员
      levelList: [
        {
          id: 0,
          label: "全部",
        },
        {
          id: 1,
          label: "会员",
        },
        {
          id: 2,
          label: "注册会员",
        },
      ], // 查询的筛选条件等级
      money_level: 0, // SVIP等级
      moneyLevelList: [], // SVIP等级信息列表
      service_id: 0, // 0全部客服
      serviceArr: [
        {
          id: 0,
          nickname: "全部",
        },
      ],
      is_push: "", // 当前IDAS权限
      is_pushArr: [
        {
          id: "",
          name: "全部",
        },
        {
          id: 0,
          name: "否",
        },
        {
          id: 1,
          name: "是",
        },
      ],
      value4: "",
      userListLoading: false, // 会员列表加载状态
      tableData: [],
      page: 1,
      total: 0,
      addUserFormShow: false, // 添加假人表单显示状态
      addUserForm: {
        nickname: "",
        live_expiration_time: "",
      }, // 添加假人表单
      addUserRules: {
        nickname: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        live_expiration_time: [
          { required: true, message: "请选择等级到期时间", trigger: "change" },
        ],
      }, // 添加假人表单验证规则
      editFormShow: false, // 编辑资料表单显示状态
      editForm: {
        id: null,
        nickname: "",
        username: "",
        mobile: "",
        qq: "",
        password: "",
        live_expiration_time: "",
        service_id: "",
        level_expiration_time: "",
        level: "",
        is_push: 0,
        money_level: "",
        bio: "",
      }, // 编辑资料表单
      formLabelWidth: "60px",
      formLabelWidth1: "100px",
      teacherList: [], // 研究员列表
      serviceList: [], // 客服列表
      is_service_admin: localStorage.getItem("is_service_admin"), // 0非管理员1管理员
      circleShow: false,
      circleList: [],
      currentUserId: null, // 当前会员id
      tableLoading: false,
      serviceId: null, // 当前账号客服id
      levelList1: [], // 编辑用户信息里面的等级列表

      order: "降序",
      is_firm_offer: 1, // 0全部1实盘2非实盘

      currentShowMobileIndex: null, // 当前显示的手机号

      dom1: 0,
      dom2: 0,
      dom3: 0,
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.initTableHeight();
    this.getBaseInfo();
    this.getUserInfo();
  },
  methods: {
    onmessage(e) {},
    initTableHeight() {
      this.dom1 = document.getElementById("dom1").offsetHeight;
      this.dom2 = document.getElementById("dom2").offsetHeight;
      this.dom3 = document.getElementById("dom3").offsetHeight;
    },
    // 修改会员V粒
    handleMoney(index, row) {
      let that = this;
      that
        .$prompt("请输入变动值（需要带加减号）", "V粒变动", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          // inputPattern: /^(([1-9][0-9]*|0)|(([0]\\.\\d{1,2}|[1-9][0-9]*\\.\\d{1,2})))$/,
          // inputErrorMessage: '变动值不合法'
        })
        .then(({ value }) => {
          that.changeMoney(row, value);
        });
    },
    async changeMoney(row, money) {
      const res = await Service.changeMoney({
        id: row.id,
        money,
        remark: "客服修改V粒",
      });
      // console.log("客服修改V粒", res);
      this.$message.success(res.msg);
      this.getUserList(this.page);
    },
    // 搜索操作
    retrieval() {
      this.getUserList(1);
    },
    // 获取基础信息
    async getBaseInfo() {
      const res = await ServiceIndex.baseInfo();
      // console.log("获取基础信息", res);
      this.teacherList = res.data.teacherList;
      this.serviceList = res.data.serviceList;
      this.levelList1 = res.data.levelList;
      this.moneyLevelList = res.data.moneyLevelList;
      this.serviceArr = this.serviceArr.concat(res.data.serviceList);
    },
    // 获取账号信息
    async getUserInfo() {
      const res = await ServiceIndex.getServiceIndex();
      this.serviceId = res.data.userinfo.user_id;
      console.log("serviceId", res.data.userinfo.user_id);
      console.log("serviceId", this.serviceId);
      this.getUserList(1);
    },
    // 获取我的会员列表
    async getUserList(page) {
      this.userListLoading = true;
      var circle_time_range0 = this.circle_time_range
        ? [...this.circle_time_range]
        : [];
      if (circle_time_range0.length) {
        circle_time_range0[0] = circle_time_range0[0] / 1000;
        circle_time_range0[1] = circle_time_range0[1] / 1000;
      }
      const res = await Service.getUserList({
        page,
        search: this.search,
        circle_time_range: circle_time_range0,
        level: this.level,
        money_level: this.money_level,
        // service_id: this.service_id,
        service_id:
          this.is_service_admin == 1 ? this.service_id : this.serviceId,
        is_push: this.is_push,
        order_field: "circle_time",
        order: this.order == "降序" ? "desc" : "asc",
        is_firm_offer: this.is_firm_offer,
      });
      // console.log("我的会员列表", res);
      this.page = page;
      this.total = res.data.total;
      var tableData = res.data.data;
      const reg = /(\d{3})\d{4}(\d{4})/;
      for (const item of tableData) {
        item.mobileText = item.mobile.replace(reg, "$1****$2");
      }
      this.tableData = tableData;
      this.userListLoading = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          switch (formName) {
            case "addUserForm":
              this.addUser();
              break;
            case "editForm":
              this.profile();
              break;
          }
        }
      });
    },
    // 添加假人
    async addUser() {
      var addUserForm = this.addUserForm;
      const res = await Service.fillUser({
        nickname: addUserForm.nickname,
        live_expiration_time: addUserForm.live_expiration_time / 1000,
      });
      // console.log("添加假人", res);
      this.$message.success(res.msg);
      this.addUserFormShow = false;
      this.getUserList(this.page);
      this.$refs["addUserForm"].resetFields();
    },
    // 修改会员信息
    async profile() {
      var editForm = this.editForm;
      var param = {
        id: editForm.id,
        nickname: editForm.nickname,
        username: editForm.username,
        qq: editForm.qq,
        password: editForm.password,
        live_expiration_time: editForm.live_expiration_time / 1000,
        service_id: editForm.service_id,
        level_expiration_time: editForm.level_expiration_time / 1000,
        level: editForm.level,
        is_push: editForm.is_push,
        money_level: editForm.money_level,
        bio: editForm.bio,
      };
      const res = await Service.profile(param);
      // console.log("修改会员信息", res);
      this.$message.success(res.msg);
      this.editFormShow = false;
      this.$refs["editForm"].resetFields();
      this.getUserList(this.page);
    },
    handleEdit(index, row) {
      this.editFormShow = true;
      console.log("handleEdit", row.service_id);
      this.editForm = {
        id: row.id,
        nickname: row.nickname,
        username: row.username,
        mobile: row.mobile,
        qq: row.qq,
        password: "",
        live_expiration_time: row.live_expiration_time * 1000,
        service_id: row.service_id,
        level_expiration_time: row.level_expiration_time * 1000,
        level: row.level.level,
        is_push: row.is_push,
        money_level: row.money_level,
        bio: row.bio,
      };
    },
    handleDelete(index, row) {
      // console.log(index, row);
    },
    handleCurrentChange(val) {
      this.getUserList(val);
      // console.log(`当前页: ${val}`);
    },
    async handleCircle(index, row) {
      this.tableLoading = true;
      const res = await Service.getUserCircleList({
        id: row.id,
      });
      // console.log("获取会员圈子信息", res);
      this.circleList = res.data;
      this.currentUserId = row.id;
      this.circleShow = true;
      this.tableLoading = false;
    },
    changeCircleStatus(teacherId, status, is_delete) {
      var text = status ? "离开" : "加入";
      var text0 = is_delete ? "彻底删除" : text;
      this.$confirm("确认将该会员" + text0 + "该圈子吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.changeCircleStatusApi(teacherId, status, is_delete);
      });
    },
    async changeCircleStatusApi(teacherId, status, is_delete) {
      let res = null;
      var circleList = this.circleList;
      if (status) {
        //离开圈子
        res = await Service.leaveCircle({
          id: this.currentUserId,
          teacher_id: teacherId,
          is_delete,
        });
      } else {
        //加入圈子
        res = await Service.joinCircle({
          id: this.currentUserId,
          teacher_id: teacherId,
        });
      }
      for (const item of circleList) {
        if (item.teacher_id == teacherId) {
          item.status = !item.status;
        }
      }
      this.circleList = circleList;
      // console.log("变更圈子状态", res);
      this.$message.success(res.msg);
    },
  },
};
</script>

<style scoped>
.body {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.head {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 15px;
}

.head > div {
  display: flex;
  align-items: center;
}

.head > div > div:first-child {
  margin-right: 10px;
  font-size: 14px;
  /* margin-bottom: 10px; */
}

.w300 {
  width: 300px;
}

.w222 /deep/ .el-form-item__content {
  width: 222px !important;
  flex: initial !important;
}

.form_cell_title {
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid #e0e9f5;
  box-sizing: border-box;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.form_cell {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.w202 /deep/.el-input__inner {
  width: 202px !important;
}

.w202 .date /deep/.el-input__suffix {
  right: 33px !important;
}

.avatar {
  width: 45px;
  height: 45px;
}
</style>
